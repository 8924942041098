.border-none[data-v-c9480434]:before {
  display: none;
}
.border-none[data-v-c9480434]:after {
  display: none;
}
.flex-fix[data-v-c9480434] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-c9480434]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-c9480434] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-c9480434] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-c9480434] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-c9480434] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-c9480434] {
  *zoom: 1;
}
.clear-fix[data-v-c9480434]:before,
.clear-fix[data-v-c9480434]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.goods-detail-scroll[data-v-c9480434] {
  height: 100vh;
  overflow: auto;
}
.goods-detail[data-v-c9480434] {
  padding-bottom: 1.1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #fff;
}
.goods-detail .goods-info[data-v-c9480434] {
  background: #fff;
  padding: 0 0.3rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.goods-detail .goods-info .goods-intro[data-v-c9480434] {
  padding: 0.1rem 0 0.2rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-bottom: 1px solid #e1e1e1;
}
.goods-detail .goods-info .goods-intro .goods-name[data-v-c9480434] {
  font-size: 0.36rem;
  color: #333333;
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  line-height: 0.52rem;
}
.goods-detail .goods-info .goods-intro .goods-tip[data-v-c9480434] {
  font-size: 0.28rem;
  color: #999999;
  line-height: 0.4rem;
}
.goods-detail .goods-info .goods-intro .goods-price[data-v-c9480434] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  margin-top: 0.14rem;
}
.goods-detail .goods-info .goods-intro .goods-price .group-title[data-v-c9480434] {
  width: 1.12rem;
  height: 0.4rem;
  line-height: 0.4rem;
  color: var(--main-color);
  font-size: 0.28rem;
  white-space: nowrap;
}
.goods-detail .goods-info .goods-intro .goods-price .real-price[data-v-c9480434] {
  color: #333333;
  margin-right: 0.16rem;
}
.goods-detail .goods-info .goods-intro .goods-price .real-price span[data-v-c9480434]:nth-child(1) {
  font-weight: bold;
  font-size: 0.36rem;
}
.goods-detail .goods-info .goods-intro .goods-price .real-price span[data-v-c9480434]:nth-child(2) {
  font-weight: bold;
  font-size: 0.5rem;
}
.goods-detail .goods-info .goods-intro .goods-price .s-price[data-v-c9480434] {
  font-size: 0.24rem;
  color: rgba(0, 0, 0, 0.38);
}
.goods-detail .goods-info .goods-intro .max-integral[data-v-c9480434] {
  margin-top: 0.14rem;
  color: var(--main-color);
}
.goods-detail .goods-info .goods-serve[data-v-c9480434] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 0.7rem;
  border-bottom: 1px solid #e1e1e1;
}
.goods-detail .goods-info .goods-serve .serve-item[data-v-c9480434] {
  width: 33%;
  font-size: 0.22rem;
  color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.goods-detail .goods-info .goods-serve .serve-item img[data-v-c9480434] {
  width: 0.24rem;
  height: 0.24rem;
  margin-right: 5px;
}
.goods-detail .goods-info .goods-buy[data-v-c9480434] {
  padding-top: 0.26rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.goods-detail .goods-info .goods-buy .buy-item[data-v-c9480434] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 0.28rem;
  padding-bottom: 0.3rem;
  line-height: 0.4rem;
}
.goods-detail .goods-info .goods-buy .buy-item .buy-title[data-v-c9480434] {
  color: #999999;
}
.goods-detail .goods-info .goods-buy .buy-item .buy-content[data-v-c9480434] {
  color: #333333;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 5rem;
}
.goods-detail .goods-info .goods-buy .buy-item .buy-content .active-tip[data-v-c9480434] {
  display: block;
  color: var(--main-color);
  padding: 0 5px;
  border: 1px solid var(--main-color);
  border-radius: 0.36rem;
  height: 0.36rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.goods-detail .goods-info .goods-buy .buy-item .buy-content .have-goods[data-v-c9480434] {
  color: var(--main-color);
}
.goods-detail .goods-info .goods-buy .buy-item .buy-content .no-goods[data-v-c9480434] {
  color: #999999;
}
.goods-detail .goods-info .goods-buy .buy-item .buy-content img[data-v-c9480434] {
  height: 0.2rem;
  margin-left: 0.14rem;
}
.goods-detail .group-content[data-v-c9480434] {
  margin-bottom: 0.2rem;
}
.goods-detail .evaluate-content[data-v-c9480434] {
  width: 6.9rem;
  margin: 0 auto;
  background: #ffffff;
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 0.38rem;
}
.goods-detail .evaluate-content .evaluate-title[data-v-c9480434] {
  padding: 0.2rem 0.2rem 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.goods-detail .evaluate-content .evaluate-title .tip[data-v-c9480434] {
  font-size: 0.24rem;
  color: #666666;
}
.goods-detail .evaluate-content .evaluate-title .look-more[data-v-c9480434] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0.24rem;
  color: var(--main-color);
}
.goods-detail .evaluate-content .evaluate-title .look-more img[data-v-c9480434] {
  height: 0.2rem;
  margin-left: 5px;
}
.goods-detail .goods-html[data-v-c9480434] {
  margin: 20px 0 0 0;
}
.goods-detail .goods-html .content-title[data-v-c9480434] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  width: 3.22rem;
  height: 0.54rem;
  border-radius: 0.54rem;
  background: rgba(108, 87, 255, 0.1);
  margin: 0 auto;
  padding: 0 0.25rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.goods-detail .goods-html .content-title .dian[data-v-c9480434] {
  width: 2px;
  height: 2px;
  background: #333333;
  border-radius: 2px;
}
.goods-detail .goods-html .content-title .line[data-v-c9480434] {
  width: 0.56rem;
  height: 1px;
  background: #333333;
}
.goods-detail .goods-html .content-title .text[data-v-c9480434] {
  font-size: 0.24rem;
  color: #333333;
}
.goods-detail .contents[data-v-c9480434] div {
  width: 100% !important;
}
.goods-detail .contents[data-v-c9480434] p {
  width: 100% !important;
}
.goods-detail .contents[data-v-c9480434] img {
  width: 100%;
  margin-bottom: -3px;
}
.goods-detail .contents[data-v-c9480434] table {
  width: 100%;
}
.goods-detail .contents[data-v-c9480434] img:last-child {
  width: 100%;
  /*margin-bottom: 1.5rem;*/
}
.goods-detail .goods-bot[data-v-c9480434] {
  width: 100%;
  height: 1.1rem;
  background: #fff;
  position: fixed;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-top: 1px solid #e1e1e1;
}
.goods-detail .goods-bot .bot-icon[data-v-c9480434] {
  width: 0.6rem;
  margin-left: 0.3rem;
  text-align: center;
}
.goods-detail .goods-bot .bot-icon img[data-v-c9480434] {
  width: 0.42rem;
  margin-bottom: 2px;
}
.goods-detail .goods-bot .bot-icon p[data-v-c9480434] {
  text-align: center;
  font-size: 0.2rem;
  line-height: 0.28rem;
  color: #333333;
  white-space: nowrap;
}
.goods-detail .goods-bot .PopUp[data-v-c9480434] {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  bottom: 0%;
  height: 2000%;
}
.goods-detail .goods-bot .PopUp .bot-mobile[data-v-c9480434] {
  width: 80%;
  position: absolute;
  height: 3rem;
  bottom: 4rem;
  left: 0.6rem;
  text-align: center;
  line-height: 80px;
  background-color: #fff;
  margin-left: 0.3rem;
  border-radius: 10px;
}
.goods-detail .goods-bot .PopUp .bot-mobile img[data-v-c9480434] {
  width: 0.5rem;
  height: 0.5rem;
  float: right;
  margin: 0.2rem;
}
.goods-detail .goods-bot .PopUp .bot-mobile .service-phone[data-v-c9480434] {
  font-size: 19px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  position: absolute;
  margin-top: 0.5rem;
}
.goods-detail .goods-bot .PopUp .bot-mobile .service-phone span[data-v-c9480434] {
  color: #000;
}
.goods-detail .goods-bot .PopUp .bot-mobile .servicepnone[data-v-c9480434] {
  display: inline-block;
  width: 2rem;
  height: 0.5rem;
  font-size: 16px;
  color: #000;
  margin-top: 0.25rem;
}
.goods-detail .goods-bot .PopUp .bot-mobile .dial[data-v-c9480434] {
  left: 0rem;
  width: 1.5rem;
}
.goods-detail .goods-bot .PopUp .bot-mobile .dial[data-v-c9480434],
.goods-detail .goods-bot .PopUp .bot-mobile .cancel[data-v-c9480434] {
  position: absolute !important;
  font-size: 15px;
  border-radius: 0 0 0 10px;
  line-height: 0.8rem !important;
  height: 0.8rem;
  width: 3rem;
  line-height: 0.5rem;
  text-align: center;
  color: #fff;
  top: 2.2rem !important;
  background: var(--main-color);
}
.goods-detail .goods-bot .PopUp .bot-mobile .cancel[data-v-c9480434] {
  left: 50% !important;
  width: 50%;
  border-radius: 0 0  10px 0;
  background: #f1f1f1;
  color: var(--main-color);
}
.goods-detail .goods-bot .buy-btn[data-v-c9480434] {
  height: 0.8rem;
  border-radius: 0.8rem;
  border: 1px solid var(--main-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 0.24rem;
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.goods-detail .goods-bot .buy-btn .add-car[data-v-c9480434],
.goods-detail .goods-bot .buy-btn .buy-now[data-v-c9480434] {
  width: 2.2rem;
  height: 0.8rem;
  text-align: center;
  font-size: 0.32rem;
  line-height: 0.8rem;
}
.goods-detail .goods-bot .buy-btn .add-car[data-v-c9480434],
.goods-detail .goods-bot .buy-btn .self-buy[data-v-c9480434] {
  color: var(--main-color);
}
.goods-detail .goods-bot .buy-btn .buy-now[data-v-c9480434],
.goods-detail .goods-bot .buy-btn .group-buy[data-v-c9480434] {
  color: #ffffff;
  background: var(--main-color);
}
.goods-detail .goods-bot .buy-btn .self-buy[data-v-c9480434],
.goods-detail .goods-bot .buy-btn .group-buy[data-v-c9480434] {
  width: 2.2rem;
  height: 0.8rem;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.goods-detail .goods-bot .buy-btn .self-buy p[data-v-c9480434]:nth-child(1),
.goods-detail .goods-bot .buy-btn .group-buy p[data-v-c9480434]:nth-child(1) {
  font-size: 0.24rem;
}
.goods-detail .goods-bot .buy-btn .self-buy p[data-v-c9480434]:nth-child(2),
.goods-detail .goods-bot .buy-btn .group-buy p[data-v-c9480434]:nth-child(2) {
  font-size: 0.26rem;
  line-height: 0.36rem;
}
.goods-detail .goods-bot .no-goods[data-v-c9480434] {
  width: 4.8rem;
  height: 0.8rem;
  background: #999999;
  text-align: center;
  line-height: 0.8rem;
  font-size: 0.32rem;
  color: #fff;
  border: 1px solid #999999;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 0.8rem;
  margin-left: 0.36rem;
}
.goods-detail .ft-weight[data-v-c9480434] {
  font-weight: bold;
}
