.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.goods-detail-scroll {
  height: 100vh;
  overflow: auto;
}
.goods-detail {
  padding-bottom: 1.1rem;
  box-sizing: border-box;
  background: #fff;
}
.goods-detail .goods-info {
  background: #fff;
  padding: 0 0.3rem;
  box-sizing: border-box;
}
.goods-detail .goods-info .goods-intro {
  padding: 0.1rem 0 0.2rem;
  box-sizing: border-box;
  border-bottom: 1px solid #e1e1e1;
}
.goods-detail .goods-info .goods-intro .goods-name {
  font-size: 0.36rem;
  color: #333333;
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  line-height: 0.52rem;
}
.goods-detail .goods-info .goods-intro .goods-tip {
  font-size: 0.28rem;
  color: #999999;
  line-height: 0.4rem;
}
.goods-detail .goods-info .goods-intro .goods-price {
  display: flex;
  align-items: baseline;
  margin-top: 0.14rem;
}
.goods-detail .goods-info .goods-intro .goods-price .group-title {
  width: 1.12rem;
  height: 0.4rem;
  line-height: 0.4rem;
  color: var(--main-color);
  font-size: 0.28rem;
  white-space: nowrap;
}
.goods-detail .goods-info .goods-intro .goods-price .real-price {
  color: #333333;
  margin-right: 0.16rem;
}
.goods-detail .goods-info .goods-intro .goods-price .real-price span:nth-child(1) {
  font-weight: bold;
  font-size: 0.36rem;
}
.goods-detail .goods-info .goods-intro .goods-price .real-price span:nth-child(2) {
  font-weight: bold;
  font-size: 0.5rem;
}
.goods-detail .goods-info .goods-intro .goods-price .s-price {
  font-size: 0.24rem;
  color: rgba(0, 0, 0, 0.38);
}
.goods-detail .goods-info .goods-intro .max-integral {
  margin-top: 0.14rem;
  color: var(--main-color);
}
.goods-detail .goods-info .goods-serve {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 0.7rem;
  border-bottom: 1px solid #e1e1e1;
}
.goods-detail .goods-info .goods-serve .serve-item {
  width: 33%;
  font-size: 0.22rem;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.goods-detail .goods-info .goods-serve .serve-item img {
  width: 0.24rem;
  height: 0.24rem;
  margin-right: 5px;
}
.goods-detail .goods-info .goods-buy {
  padding-top: 0.26rem;
  box-sizing: border-box;
}
.goods-detail .goods-info .goods-buy .buy-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.28rem;
  padding-bottom: 0.3rem;
  line-height: 0.4rem;
}
.goods-detail .goods-info .goods-buy .buy-item .buy-title {
  color: #999999;
}
.goods-detail .goods-info .goods-buy .buy-item .buy-content {
  color: #333333;
  display: flex;
  align-items: center;
  max-width: 5rem;
}
.goods-detail .goods-info .goods-buy .buy-item .buy-content .active-tip {
  display: block;
  color: var(--main-color);
  padding: 0 5px;
  border: 1px solid var(--main-color);
  border-radius: 0.36rem;
  height: 0.36rem;
  display: flex;
  align-items: center;
}
.goods-detail .goods-info .goods-buy .buy-item .buy-content .have-goods {
  color: var(--main-color);
}
.goods-detail .goods-info .goods-buy .buy-item .buy-content .no-goods {
  color: #999999;
}
.goods-detail .goods-info .goods-buy .buy-item .buy-content img {
  height: 0.2rem;
  margin-left: 0.14rem;
}
.goods-detail .group-content {
  margin-bottom: 0.2rem;
}
.goods-detail .evaluate-content {
  width: 6.9rem;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 0.38rem;
}
.goods-detail .evaluate-content .evaluate-title {
  padding: 0.2rem 0.2rem 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.goods-detail .evaluate-content .evaluate-title .tip {
  font-size: 0.24rem;
  color: #666666;
}
.goods-detail .evaluate-content .evaluate-title .look-more {
  display: flex;
  align-items: center;
  font-size: 0.24rem;
  color: var(--main-color);
}
.goods-detail .evaluate-content .evaluate-title .look-more img {
  height: 0.2rem;
  margin-left: 5px;
}
.goods-detail .goods-html {
  margin: 20px 0 0 0;
}
.goods-detail .goods-html .content-title {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 3.22rem;
  height: 0.54rem;
  border-radius: 0.54rem;
  background: rgba(108, 87, 255, 0.1);
  margin: 0 auto;
  padding: 0 0.25rem;
  box-sizing: border-box;
}
.goods-detail .goods-html .content-title .dian {
  width: 2px;
  height: 2px;
  background: #333333;
  border-radius: 2px;
}
.goods-detail .goods-html .content-title .line {
  width: 0.56rem;
  height: 1px;
  background: #333333;
}
.goods-detail .goods-html .content-title .text {
  font-size: 0.24rem;
  color: #333333;
}
.goods-detail .contents /deep/ div {
  width: 100% !important;
}
.goods-detail .contents /deep/ p {
  width: 100% !important;
}
.goods-detail .contents /deep/ img {
  width: 100%;
  margin-bottom: -3px;
}
.goods-detail .contents /deep/ table {
  width: 100%;
}
.goods-detail .contents /deep/ img:last-child {
  width: 100%;
  /*margin-bottom: 1.5rem;*/
}
.goods-detail .goods-bot {
  width: 100%;
  height: 1.1rem;
  background: #fff;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  border-top: 1px solid #e1e1e1;
}
.goods-detail .goods-bot .bot-icon {
  width: 0.6rem;
  margin-left: 0.3rem;
  text-align: center;
}
.goods-detail .goods-bot .bot-icon img {
  width: 0.42rem;
  margin-bottom: 2px;
}
.goods-detail .goods-bot .bot-icon p {
  text-align: center;
  font-size: 0.2rem;
  line-height: 0.28rem;
  color: #333333;
  white-space: nowrap;
}
.goods-detail .goods-bot .PopUp {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  bottom: 0%;
  height: 2000%;
}
.goods-detail .goods-bot .PopUp .bot-mobile {
  width: 80%;
  position: absolute;
  height: 3rem;
  bottom: 4rem;
  left: 0.6rem;
  text-align: center;
  line-height: 80px;
  background-color: #fff;
  margin-left: 0.3rem;
  border-radius: 10px;
}
.goods-detail .goods-bot .PopUp .bot-mobile img {
  width: 0.5rem;
  height: 0.5rem;
  float: right;
  margin: 0.2rem;
}
.goods-detail .goods-bot .PopUp .bot-mobile .service-phone {
  font-size: 19px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
  margin-top: 0.5rem;
}
.goods-detail .goods-bot .PopUp .bot-mobile .service-phone span {
  color: #000;
}
.goods-detail .goods-bot .PopUp .bot-mobile .servicepnone {
  display: inline-block;
  width: 2rem;
  height: 0.5rem;
  font-size: 16px;
  color: #000;
  margin-top: 0.25rem;
}
.goods-detail .goods-bot .PopUp .bot-mobile .dial {
  left: 0rem;
  width: 1.5rem;
}
.goods-detail .goods-bot .PopUp .bot-mobile .dial,
.goods-detail .goods-bot .PopUp .bot-mobile .cancel {
  position: absolute !important;
  font-size: 15px;
  border-radius: 0 0 0 10px;
  line-height: 0.8rem !important;
  height: 0.8rem;
  width: 3rem;
  line-height: 0.5rem;
  text-align: center;
  color: #fff;
  top: 2.2rem !important;
  background: var(--main-color);
}
.goods-detail .goods-bot .PopUp .bot-mobile .cancel {
  left: 50% !important;
  width: 50%;
  border-radius: 0 0  10px 0;
  background: #f1f1f1;
  color: var(--main-color);
}
.goods-detail .goods-bot .buy-btn {
  height: 0.8rem;
  border-radius: 0.8rem;
  border: 1px solid var(--main-color);
  display: flex;
  margin-left: 0.24rem;
  overflow: hidden;
  box-sizing: border-box;
}
.goods-detail .goods-bot .buy-btn .add-car,
.goods-detail .goods-bot .buy-btn .buy-now {
  width: 2.2rem;
  height: 0.8rem;
  text-align: center;
  font-size: 0.32rem;
  line-height: 0.8rem;
}
.goods-detail .goods-bot .buy-btn .add-car,
.goods-detail .goods-bot .buy-btn .self-buy {
  color: var(--main-color);
}
.goods-detail .goods-bot .buy-btn .buy-now,
.goods-detail .goods-bot .buy-btn .group-buy {
  color: #ffffff;
  background: var(--main-color);
}
.goods-detail .goods-bot .buy-btn .self-buy,
.goods-detail .goods-bot .buy-btn .group-buy {
  width: 2.2rem;
  height: 0.8rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.goods-detail .goods-bot .buy-btn .self-buy p:nth-child(1),
.goods-detail .goods-bot .buy-btn .group-buy p:nth-child(1) {
  font-size: 0.24rem;
}
.goods-detail .goods-bot .buy-btn .self-buy p:nth-child(2),
.goods-detail .goods-bot .buy-btn .group-buy p:nth-child(2) {
  font-size: 0.26rem;
  line-height: 0.36rem;
}
.goods-detail .goods-bot .no-goods {
  width: 4.8rem;
  height: 0.8rem;
  background: #999999;
  text-align: center;
  line-height: 0.8rem;
  font-size: 0.32rem;
  color: #fff;
  border: 1px solid #999999;
  box-sizing: border-box;
  border-radius: 0.8rem;
  margin-left: 0.36rem;
}
.goods-detail .ft-weight {
  font-weight: bold;
}
